export function getStorage(key) {
    const value = window.localStorage.getItem(key)
    try {
        return JSON.parse(window.localStorage.getItem(key))
    } catch (error) {
        return value
    }
}
export function setStorage(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}
export function removeStorage(key) {
    window.localStorage.removeItem(key)
}
