<template>
  <div class="waymon_toast" v-if="state.isDialog">{{state.title}}</div>
</template>

<script setup>
import {reactive} from "vue";
  const state = reactive({
     isDialog:false,
     title:""
  })
  const showToast = (val,cb) => {
    state.isDialog = true
    state.title = val
    setTimeout(()=>{
      state.isDialog = false
      typeof cb == 'function' && cb(null,"")
    },2500)
  }
  defineExpose({
    showToast
  })
</script>

<style scoped>
.waymon_toast{
  position: fixed;
  z-index: 99999;
  bottom: 100px;
  width: 76%;
  left: 12%;
  height: 55px;
  line-height: 55px;
  background: rgb(245, 245, 245,0.45);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
}
</style>
