import {createRouter, createWebHashHistory} from 'vue-router'
import {getStorage} from "@/utils/util";

const routes = [
  {
    path: '/',
    name: 'Waymon',
    redirect: '/Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: ()=> import('../views/Home.vue'),
    meta: {
      title: 'citas',
      icon: require('../assets/tabbar/1.png'),
      icon_s: require('../assets/tabbar/1.png'),
      showTabBar: true
    }
  },
  {
    path: '/Order',
    name: 'Order',
    component: ()=> import('../views/Order.vue'),
    meta: {
      title: 'invertir',
      icon: require('../assets/tabbar/2.png'),
      icon_s: require('../assets/tabbar/2.png'),
      showTabBar: true
    }
  },
  {
    path: '/Member',
    name: 'Member',
    component: ()=> import('../views/Member.vue'),
    meta: {
      title: 'mio',
      icon: require('../assets/tabbar/3.png'),
      icon_s: require('../assets/tabbar/3.png'),
      showTabBar: true
    }
  },{
    path: '/Contract',
    name: 'Contract',
    component: ()=> import('../views/Contract.vue'),
  },{
    path: '/Authen',
    name: 'Authen',
    component: ()=> import('../views/Authen.vue'),
  },{
    path: '/Login',
    name: 'Login',
    component: ()=> import('../views/Login.vue'),
  },{
    path: '/Register',
    name: 'Register',
    component: ()=> import('../views/Register.vue'),
  },{
    path: '/Message',
    name: 'Message',
    component: ()=> import('../views/Message.vue'),
  },{
    path: '/Setting',
    name: 'Setting',
    component: ()=> import('../views/Setting.vue'),
  },{
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: ()=> import('../views/OrderDetail.vue'),
  },{
    path: '/Platform',
    name: 'Platform',
    component: ()=> import('../views/Platform.vue'),
  },{
    path: '/Recharge',
    name: 'Recharge',
    component: ()=> import('../views/Recharge.vue'),
  },{
    path: '/Retiros',
    name: 'Retiros',
    component: ()=> import('../views/Retiros.vue'),
  },{
    path: '/Personal',
    name: 'Personal',
    component: ()=> import('../views/Personal.vue'),
  },{
    path: '/Password',
    name: 'Password',
    component: ()=> import('../views/Password.vue'),
  },{
    path: '/Payment',
    name: 'Payment',
    component: ()=> import('../views/Payment.vue'),
  },{
    path: '/Amount',
    name: 'Amount',
    component: ()=> import('../views/Amount.vue'),
  },{
    path: '/WithPass',
    name: 'WithPass',
    component: ()=> import('../views/WithPass.vue'),
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断当前运行环境是否为生产环境
  if (process.env.NODE_ENV === 'production') {

  } else {

  }
  const token = getStorage('token')
  const pages = ['/Login','/Register']
  if (!token && !pages.includes(to.path)) {
      next('/Login')
  }
  next()
})

export default router
