import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'vant/lib/index.css'
import './css/common.css'

import esES from 'vant/es/locale/lang/es-ES';


import {
    ConfigProvider,
    Tabbar,
    TabbarItem,
    Swipe,
    SwipeItem,
    Popup,
    Uploader,
    PullRefresh,
    List,
    CountDown,
    Locale,
    Checkbox,
    Tab,
    Tabs,
    Sticky
} from "vant";


const app =createApp(App)

app.use(ConfigProvider)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(Popup)
    .use(Sticky)
    .use(Uploader)
    .use(PullRefresh)
    .use(List)
    .use(CountDown)
    .use(Locale)
    .use(Checkbox)
    .use(Tabs)
    .use(Tab)

Locale.use('es-ES', esES);

app.use(store)
    .use(router)

app.mount('#app')
